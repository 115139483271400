import React, { useEffect, useRef, useState } from 'react';
import './Checkpoints.css';
import { create_card_checkpoint, create_checkpoint, get_checkpoints, get_current_cards, update_dashboard } from '../../../utils/talent_services';
import { useNavigate } from 'react-router-dom';

const Checkpoints = ({usr_id}) => {
    const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [checkpoints, setCheckpoints] = useState([]);
  const [current_checkpoint, set_current_checkpoint] = useState([]);

  const [cards, setCards] = useState([]);
  const [countTarjetas, setcountTarjetas] = useState("");
  const [countTarjetasLoaded, setcountTarjetasLoaded] = useState(0);
  
  const [selectedCheckpoint, setSelectedCheckpoint] = useState(null);



  const selectCheckpoint = (index, id) => {
    setSelectedCheckpoint(index);
    set_current_checkpoint(id);
  };

  



  const refresh_checkpoints = () => {
    async function fetchCheckpoints() {
        const checkpointlist = await get_checkpoints(token);
        if (checkpointlist==="INVALID"){
          navigate("/");
        }else{
          const checkpointNames = checkpointlist.map((element, index) => {
              return { id: element.id, date_creation: element.date_creation, cards_count: element.cards_count, index: index };
            });
          setCheckpoints(checkpointNames);
       }
      }
      fetchCheckpoints();
  }

  

  const refresh_current_cards = () => {
    async function fetchCheckpoints() {
        const currentCardslist_byKR = await get_current_cards(token);
        if (currentCardslist_byKR==="INVALID"){
          navigate("/");
        }else{


            setCards([]);
            let countTarjetas =0;
            currentCardslist_byKR.forEach(element => {
                const krHead = {isKr:true, krid:element.id, krname: element.name };

                setCards(prevCards => [...prevCards, krHead]);

                const currentKr = element['cards'].map((celement, index) => {
                    countTarjetas+=1;
                    return {isKr:false, krid: element.id, cardid: celement.cardid, cardname: celement.cardname, shortUrl:celement.shortUrl, members: celement.members, desc:celement.desc, index: index };
                  });

                  setcountTarjetas(countTarjetas);
                  setCards(prevCards => [...prevCards, ...currentKr]);
            });
            
          
           
       }
      }
      fetchCheckpoints();
  }



  
  useEffect(() => {
    refresh_current_cards();
    refresh_checkpoints();
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia

async function fetch_create_card(thecard, checkpoint_id) {
    const newCheckPointResult = await create_card_checkpoint(token, thecard, checkpoint_id);
    if (newCheckPointResult==="INVALID"){
      navigate("/");
    }else{
      setcountTarjetasLoaded((prevCount) => prevCount + 1);
   }

}

const handlebt_create_checkpoint = () => {
  setcountTarjetasLoaded(0);
    cards.forEach(element => {
        if (!element.isKr){
            fetch_create_card(element, current_checkpoint);
        }
    });
    refresh_checkpoints();
}

const crearCheckPoint = () => {

    async function fetch_create_Checkpoints() {
        const ahora = new Date();
        const hora = ahora.toTimeString().split(' ')[0].slice(0, 5); 
        const checkpointlist = await create_checkpoint(token, usr_id);
        if (checkpointlist==="INVALID"){
          navigate("/");
        }else{

            refresh_checkpoints();
       }
      }
      fetch_create_Checkpoints();
    
  };

  
const updateDashboard = () => {

  async function fetch_update_Dashboard() {

      const checkpointlist = await update_dashboard(token);
      if (checkpointlist==="INVALID"){
        navigate("/");
      }else{
        alert("Dashboard de talento actualizado con las últimas evaluaciones.");
      }
    }
    fetch_update_Dashboard();
  
};


  return (
    <div className="containerChk">
      <div className="left-panel3">
        <h3>Checkpoints:</h3>
        <div className="checkpoints-list">
        <table >
          <thead>
            <tr>
              <th style={{ width: '10%' }}>id</th>
              <th style={{ width: '75%' }}>Fecha</th>
              <th style={{ width: '10%' }}>Tarjetas</th>
            </tr>
          </thead>
          <tbody>
            {checkpoints.map((checkpoint, index) => (
              <tr
                key={checkpoint.id}
                className={selectedCheckpoint === index ? 'selected' : ''}
                onClick={() => selectCheckpoint(index, checkpoint.id)}
              >
                <td style={{ width: '10%' }}>{checkpoint.id}</td>
                <td style={{ width: '75%' }}>{checkpoint.date_creation}</td>
                <td style={{ width: '10%' }}>{checkpoint.cards_count}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        <div >
          <button className='bt-function' onClick={crearCheckPoint}>Crear Nuevo Checkpoint</button>
        </div>

        <div >
          <button className='bt-function' onClick={updateDashboard}>Actualizar Dashboard</button>
        </div>
      </div>

      <div className="right-panel3">
        <h3> {countTarjetas}  Tarjetas en Ejecución/ {countTarjetasLoaded} procesadas.</h3>
        <div className='cards-list3'>
        <table>
          <thead>
            <tr>
              <th>Título</th>
              
            </tr>
          </thead>
          <tbody>
            {cards.map((card, index) => {

                if (card.isKr){
                    return (
                        <tr className='kr-head'  >
          
                          <td>{card.krname}</td>
                        </tr>
                      )
                }else{

                    return (
                        <tr
                          key={index}
                        >
                        <td><a href={card.shortUrl} target="_blank">{card.cardname}</a></td>
                        
                        </tr>
                      )
                }
              })}
          </tbody>
        </table>
        </div>
        <button className="add-cards-btn" onClick={handlebt_create_checkpoint}>
          Agregar Tarjetas al Checkpoint: {current_checkpoint}
        </button>
      </div>
    </div>
  );
};

export default Checkpoints;
