import React, { useEffect, useState } from 'react';
import './TOMi8versions.css';
import { create_new_version, deploy_update, get_models, get_projects, getPackages, getPackageVersion } from '../../utils/services';
import {  useNavigate } from 'react-router-dom';
import Modal_proyectos from '../menus/Modal_proyectos_brevo';
import Select from "react-select";

function TOMi8versions  ({permission}) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]); // Estado para las opciones del dropdown
  const [versions, setVersions] = useState([]);
  const [VersionsCombo, setVersionsCombo] = useState([]);
  const [file, setFile] = useState(null);
  const [fileEN, setENFile] = useState(null);
  const [fileES, setESFile] = useState(null);
  const [fileAR, setARFile] = useState(null);
  const [filePT, setPTFile] = useState(null);
  const [filevideoBanner, setfilevideoBanner] = useState(null);
  const [version2deploy, setversion2deploy] = useState(0);
  const [selectedProject, setselectedProject] = useState(0);
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedCapacity, setselectedCapacity] = useState("");
  const [selectedPackagename, setSelectedPackage] = useState("");
    const [projects, setprojects] = useState([]);
    
      const [modelsListOpt, setModelsListOpt] = useState([]); // Estado para las opciones del dropdown
    
    
  const [newVersion, setNewVersion] = useState({
    versionCode: '',
    versionName: '',
    fechaPublish: '',
    comments: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVersion((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {

      setFile ( e.target.files[0]);
    console.log('Selected file:', file);
  
  };

  
  const handleindexFileChange = (e, lang) => {
    console.log("idiomas" , e.target.files[0].name, lang);
    switch (lang){
      case "en":
        setENFile(e.target.files[0]);
        break;
      case "es":
        setESFile(e.target.files[0]);
        break;
      case "ar":
        setARFile(e.target.files[0]);
        break;
      case "pt":
        setPTFile(e.target.files[0]);
        break;
      case "vid":
        setfilevideoBanner(e.target.files[0]);
        break;

    }

};


  const addVersion = () => {
    
    if (selectedPackagename === ""){
      alert("Debe seleccionar un paquete al cual se asocia la actualización");
      return;
    }

    if (file === "" || newVersion.comments === "" || newVersion.versionCode === "" || newVersion.versionName === ""){
      alert("Debe completar todos los datos");
      return;
    }

    async function fetchAddVersion() {
      const packageslist = await create_new_version(token, selectedPackagename , file, newVersion.comments, newVersion.versionCode, newVersion.versionName, fileEN,fileES,fileAR,filePT, filevideoBanner);
      if (packageslist==="INVALID"){
        navigate("/");
      }else{
        
        refreshlist(selectedPackagename);
        setModalOpen(false);
        
     }
    }

    fetchAddVersion();

  };



  useEffect(() => {

    async function fetchPackages() {
      const packageslist = await getPackages(token);
      if (packageslist==="INVALID"){
        navigate("/");
      }else{

        console.log("Lista: " + packageslist[0].package_name);
        
        const packageNames = packageslist.map(element => element.package_name);
        packageNames.unshift("Select one...");
        setDropdownOptions(packageNames);
        
     }
    }

    fetchPackages();
    if (permission ==="1") 
      { 
        refresh_projects_list();
        fetchgetModels();
      }
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia


  const refresh_projects_list = () => {
      async function fetchCheckpoints() {
          const membersList = await get_projects(token);
          if (membersList==="INVALID"){
            navigate("/");
          }else{
            const members = membersList
            .filter(element => element.t8s>0)
            .map((element, index) => {
              return {id:element.id, label: element.project_name + " ( " +  element.t8s + " Unds)", value: element.project_id,   index: index };
            });

            members.unshift(
              {
                id:0,
                label: "Todos",
                value:""
              }
            )

            setprojects(members);
                    
         }
        }
        fetchCheckpoints();
    }


  const refreshlist = (packageName) =>{

    async function fetchVersions(packageName) {
      const packageVersionlist = await getPackageVersion(token, packageName);
      if (packageVersionlist==="INVALID"){
        navigate("/");
      }else{
        try{
          const newVersions = packageVersionlist.map(item => ({
            versionCode: item.version,
            versionName: item.version_name,
            fechaPublish: item.release_date,
            comments: item.description,
            url: item.url,
            info_url: item.info_url,
          }));
          const newVersionsCombo = packageVersionlist.map(item => ({
            versionCode: item.version,
            versionName: item.version_name,
          }));
          newVersionsCombo.unshift({
            versionCode: 0,
            versionName: " (Ninguno)",
          });

          setVersions(newVersions);
          setVersionsCombo(newVersionsCombo);
        }catch (err) {
          
        }

        
     }
    }
    fetchVersions(packageName);

  }


  
    async function fetchgetModels() {
      const modelsList = await get_models(token);
      if (modelsList==="INVALID"){
        navigate("/");
      }else{
        const modelsNames = modelsList.map(element => element.reference);
        modelsNames.unshift("Todos");
        setModelsListOpt(modelsNames);
        
     }
    }


  const deployUpdate = () =>{
    console.log("Deploy Updt", version2deploy, selectedProject, selectedCapacity, selectedModel);

    if (window.confirm("¿Estás seguro de actualizar los TOMi8 que complan con: "
                        + "\n proyecto: " + selectedProject 
                        + "\n capacidad: " + selectedCapacity 
                        + "\n con modelo: " + selectedModel
                        + "\n a la version ***** " + version2deploy
                        + " ***** del paquete ---->>>>>" + selectedPackagename

    )) {
      fetchDeployUpdate();
    }
    
    async function fetchDeployUpdate () {
      const modelsList = await deploy_update(token, selectedProject, selectedCapacity, selectedModel, version2deploy, selectedPackagename);
      if (modelsList==="INVALID"){
        navigate("/");
      }else{
        const modelsNames = modelsList.map(element => element.reference);
        modelsNames.unshift("Todos");
        setModelsListOpt(modelsNames);
        
     }
    }
  }

  const handleDropdownChange = (e) => {

    refreshlist(e.target.value);
    setSelectedPackage(e.target.value);
  };

  return (
    <div className="gobal">
    <div className="containerVersions">
      <div className="dropdown">
        <select className="dropdown-select" onChange={handleDropdownChange}>
           {dropdownOptions.map((option, index) => (
            <option key={index} value={option}  >
              {option}
            </option>
          ))}
        </select>
        
        {permission === "1" ? 
          <button onClick={() => setModalOpen(true)} className="add-button">Subir nueva version</button>
          :null
        }
    
      </div>

      <div className="table-container">
        <table className="version-table">
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Date</th>
              <th>Comments</th>
              <th>url</th>
              <th>info-es</th>
              <th>info-en</th>
              <th>info-pt</th>
              <th>info-ar</th>
            </tr>
          </thead>
          <tbody>
            {versions.map((version, index) => (
              <tr key={index}>
                <td style={{ width: '5%' }}>{version.versionCode}</td>
                <td style={{ width: '5%' }}>{version.versionName}</td>
                <td style={{ width: '10%' }}>{version.fechaPublish}</td>
                <td style={{ width: '55%', whiteSpace: "pre-line" }}>{version.comments}</td>
                <td style={{ width: '5%' }}><a href={version.url} taget="_blank">APK</a></td>
                <td style={{ width: '5%' }}><a href={version.info_url+"/index-es.html" } target="_blank">index-es</a></td>
                <td style={{ width: '5%' }}><a href={version.info_url+"/index-en.html"} target="_blank">index-en</a></td>
                <td style={{ width: '5%' }}><a href={version.info_url+"/index-pt.html"} target="_blank">index-pt</a></td>
                <td style={{ width: '5%' }}><a href={version.info_url+"/index-ar.html"} target="_blank">index-ar</a></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>


      <Modal_proyectos isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <div className="new-version">
            <h3>Nueva Version</h3>
            <div className="form-group">
              <label>Comments:</label>
              <textarea
                name="comments"
                value={newVersion.comments}
                onChange={handleInputChange}
                className="textarea"
              />
            </div>
            <div className="form-group">
              <label>Version Code:</label>
              <input
                type="text"
                name="versionCode"
                value={newVersion.versionCode}
                onChange={handleInputChange}
                className="input"
              />
              <label>Version Name:</label>
              <input
                type="text"
                name="versionName"
                value={newVersion.versionName}
                onChange={handleInputChange}
                className="input"
              />
            
            </div>
            
            <div className="form-group">
              <label>Package:</label>
              <input type="file" onChange={handleFileChange} className="file-input" accept="Android/Package, .apk" />

             
            </div>
            <div className="form-group">
              <label>Index-es:</label>
              <input type="file" onChange={(e) => handleindexFileChange(e,"es")} className="file-input" accept="Pagina Web, .html" />
              </div>
            <div className="form-group">
              <label>Index-en:</label>
              <input type="file" onChange={(e) => handleindexFileChange(e,"en")} className="file-input" accept="Pagina Web, .html" />
            </div>
            <div className="form-group">
              <label>Index-ar:</label>
              <input type="file" onChange={(e) => handleindexFileChange(e,"ar")} className="file-input" accept="Pagina Web, .html" />
            </div>
            <div className="form-group"> 
              <label>Index-pt:</label>
            <input type="file" onChange={(e) => handleindexFileChange(e,"pt")} className="file-input" accept="Pagina Web, .html" />
            </div>
            <div className="form-group">
              <label>Video Banner:</label>
              <input type="file" onChange={(e) => handleindexFileChange(e,"vid")} className="file-input" accept="Video Banner, .mp4" />
            </div>
            <div className="form-group">
            <button onClick={addVersion} className="add-button">Agregar</button>
            </div>
          </div>
      </Modal_proyectos>
      </div>


      { selectedPackagename != "" && permission =="1"?
      <div className="card">
          <label className="title">
            Desplegar {selectedPackagename} versión:  
            
              <select   onChange={(e) => setversion2deploy(e.target.value)}>
                    {VersionsCombo.map((option, index) => (
                    <option key={index} value={option.versionCode}  >
                      {option.versionCode + " (v" + option.versionName + ")"}
                    </option>
                  ))}

              </select>
                          
                     
          </label>

          <div className="section">
            <div className="row">
              <label className="label">Que pertenezcan al proyecto: </label>
              <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={projects}
                            onChange={(selectedOption) => setselectedProject(selectedOption.value)}
                            placeholder="Buscar producto..."
                            isSearchable
                          />
            </div>
          </div>

          <div className="section">
            <div className="row">
              <label className="label">Según su capacidad:</label>
              <select className="select" onChange ={(e) => setselectedCapacity(e.target.value)}>
                <option value="">Todas</option>
                <option value="TLT">TLT (64)</option>
                <option value="T128">T128</option>
                <option value="T256">T256</option>
                <option value="T512">T512</option>
                <option value="T1TB">T1TB</option>
              </select>
              <label className="label">Del modelo:</label>
              <select className="dropdown-select" onChange ={(e) => setSelectedModel(e.target.value)}>
                  {modelsListOpt.map((option, index) => (
                    <option key={index} value={option}  >
                      {option}
                    </option>
                  ))}
              </select>

            </div>
          </div>

          <div className="button-container">
            <button className="button-yellow" onClick ={() => deployUpdate()}>Enviar actualización Actualizar</button>
          </div>
          <div>
          <a href='https://lookerstudio.google.com/u/0/reporting/ab6f9d3b-17d1-4886-adcd-c5fcb4ed94e7/page/p_vpsk8al2md/edit' target="_blank" >Dashboard de despliegue</a>
          </div>
      </div>
      :null
      }


      </div>
      

  );
};

export default TOMi8versions;
