import React, { useEffect, useRef, useState } from 'react';
import './TalentStep2.css';
import {  get_checkpoints, save_user_learning } from '../../../utils/talent_services';
import { useNavigate } from 'react-router-dom';

function TalentStep4({ usr_id }) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [current_checkpoint, set_check_point] = useState([]);
  const newAnnotationText = useRef(null);
  const newLink = useRef(null);

  

  const validate_last_checkpoint = () => {
    async function fetchCheckpoints() {
        const checkpointlist = await get_checkpoints(token);
        if (checkpointlist === "INVALID") {
          navigate("/");
        } else {
          let checkPoint_id = checkpointlist[0].id;
          set_check_point(checkPoint_id);
        }
      }
    fetchCheckpoints();
  };



  
  const newAnnotation = () => {
    console.log ("Anotacion nueva> " + newAnnotationText.current.value );

    async function fetchUserAnnotation() {
      
        if (newAnnotationText.current.value ===""){
           navigate("/done");
        }else{
      
          const deviceMessages = await save_user_learning(token, newAnnotationText.current.value,newLink, current_checkpoint);
          if (deviceMessages==="INVALID"){
            navigate("/");
          }else{
              navigate("/done");
              newAnnotationText.current.value = "";

          }
      }
      }

      fetchUserAnnotation();
    
  }
  
  useEffect(() => {
    validate_last_checkpoint();
  }, [token]);

  

  return (
    <div className="containerChk">
      <div className="right-panel">
        <div className="button-container">
          <button className="add-cards-btn" onClick={newAnnotation}>
            Terminar
          </button>
        </div>
        <h4>
        Cuéntanos algún aprendizaje o lección práctica que quieras dejar para TOMi de este sprint. Estos aprendizajes se integrarán a nuestro banco de conocimiento para mejorar procesos y prevenir problemas futuros:
        </h4>
       
        <div className="new-annotation">

          <textarea
              id="annotation-text"
              rows="10"
              cols="50"
              placeholder="ie: Cuándo un formador vaya a viajar debe ir bien identificado. En este sprint tuvimos problema en una zona rural en la que no dejaron pasar a kike..."
              ref={newAnnotationText}
          />
        
      
        </div>


          <textarea
              id="link"
              rows="1"
              cols="50"
              placeholder="Si tienes un doc que hayas creado sobre esto, pega aqui el link de drive."
              ref={newLink}
          />


      
      
      </div>

    </div>
  );
}

export default TalentStep4;


