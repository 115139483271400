import { useState } from "react";
import {  useNavigate } from "react-router-dom";

function T8_versionmanager_menu({setactivity, permission}) {
  const [isProductionOpen, setProductionOpen] = useState(true); // Predeterminadamente abierto
 const navigate = useNavigate();
  return (
    <li className="menu-item">
          <div onClick={() => setProductionOpen(!isProductionOpen)} className="menu-title">
            <span role="img" aria-label="key">🥏</span> Devices Manager
            <span className={`arrow ${isProductionOpen ? 'open' : ''}`}>▾</span>
          </div>
          {isProductionOpen && (
            <ul className="submenu">
              <li onClick={() => navigate('/Models')}>Modelos de TOMi8</li>
              <li onClick={() => navigate('/Versions')}>Versiones de apps</li>
              {
                permission === "1"?
              <li>Radical Lock (not avalible)</li>
              :null
              }
            </ul>
          )}
        </li>
  );
}

export default T8_versionmanager_menu;
