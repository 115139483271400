import React, { useState, useEffect, useRef } from 'react';
import dashboarinmg from '../../assets/dashboard.png';
import wikiimg from '../../assets/wiki.png';
import ppiosimg from '../../assets/principios.png';
import practicasimg from '../../assets/practicas.png';
import procimg from '../../assets/procedimientos.png';
import companydashimg from '../../assets/company.png';
import tomi8usagedashimg from '../../assets/tomi8 usage.png';
import krsdashimg from '../../assets/krs.png';

import './Dashboard.css';
const Dashboard = ({usr_id}) => {

return(
    <div className="containerDashboard">

      <div className="DashboardsRow">
         <div className="carddash">
          <a href="https://lookerstudio.google.com/u/0/reporting/7f2be3ab-1a8a-4ab2-9129-45e70d331e3b/page/k1BTD" target="_blank" rel="noopener noreferrer">
            <div className="image-box2">
              <img src={companydashimg}></img>
            </div>
            <p className="title2">Dashboard Compañia</p>
          </a>
        </div>
        <div className="carddash">
          <a href="https://lookerstudio.google.com/u/0/reporting/ab6f9d3b-17d1-4886-adcd-c5fcb4ed94e7/page/ZAGwD/edit" target="_blank" rel="noopener noreferrer">
            <div className="image-box2">
              <img src={tomi8usagedashimg}></img>
            </div>
            <p className="title2">TOMi8 Usage</p>
          </a>
        </div>
        <div className="carddash">
          <a href="https://lookerstudio.google.com/u/0/reporting/49a417f1-2d49-4ce0-a608-d24c652ef12c/page/k1BTD" target="_blank" rel="noopener noreferrer">
            <div className="image-box2">
              <img src={krsdashimg}></img>
            </div>
            <p className="title2">Dashboard KRs</p>
          </a>
        </div>
        <div className="carddash">
          <a href="https://lookerstudio.google.com/u/0/reporting/e4c85877-b473-4287-889d-200e7a1292f8/page/p_brs2rrvbmd" target="_blank" rel="noopener noreferrer">
            <div className="image-box2">
              <img src={dashboarinmg}></img>
            </div>
            <p className="title2">Estrategias</p>
          </a>
        </div>
       </div>

        <div className="DashboardsRow">
          <div className="carddash">
            <a href="https://sites.google.com/tomi.digital/wiki/inicio?authuser=0" target="_blank" rel="noopener noreferrer">
              <div className="image-box2">
                <img src={wikiimg}></img>
              </div>
              <p className="title2">Wiki de la organización</p>
            </a>
          </div>
          <div className="carddash">
            <a href="https://sites.google.com/tomi.digital/wiki/sobre-el-modelo/principios?authuser=0" target="_blank" rel="noopener noreferrer">
              <div className="image-box2">
                <img src={ppiosimg}></img>
              </div>
              <p className="title2">Principios de la organización</p>
            </a>
          </div>

          <div className="carddash">
            <a href="https://sites.google.com/tomi.digital/wiki/d%C3%ADa-a-d%C3%ADa/pr%C3%A1cticas?authuser=0" target="_blank" rel="noopener noreferrer">
              <div className="image-box2">
                <img src={practicasimg}></img>
              </div>
              <p className="title2">Prácticas</p>
            </a>
          </div>
          
          <div className="carddash">
            <a href="https://sites.google.com/tomi.digital/wiki/d%C3%ADa-a-d%C3%ADa/procedimientos?authuser=0" target="_blank" rel="noopener noreferrer">
              <div className="image-box2">
                <img src={procimg}></img>
              </div>
              <p className="title2">Procedimientos</p>
            </a>
          </div>
        </div>

    </div>  
);
    
}

export default  Dashboard;