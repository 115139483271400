import React, { useState, useEffect, useRef } from 'react';
import { loadIssueType, get_devices_info, save_new_issue, update_destination, update_issue, getPackages, get_package_versions } from '../../utils/services';
import './Units_status.css';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Units_status = ({usr_id, permission}) => {

  const token = localStorage.getItem("token");
  const [deviceMSG, setIssues] = useState([]);

  const [searchParams] = useSearchParams();
  const serial_url = searchParams.get("serial");
  const [model, setModel] = useState("");
  const [packages, setPackages] = useState([]);
  const [manufactured_date, setManufactured_date] = useState("");
  const [item_status, setitem_status] = useState("");
  const [warranty_covered, setwarranty_covered] = useState("");
  const [dasboard_link, setdasboard_link] = useState("");
  const [usr_name, setusr_name] = useState("");
  const [editIndex, setEditIndex] = useState(null);
 
  const [newType, setNewType] = useState("");
  const [estado, setestado] = useState(0);
  const [canal, setcanal] = useState("");
  const [issue_updating_id, setissue_updating_id] = useState(0);


  const updating = useRef(false);
  const serialInputRef = useRef(null);
  const persona_contacto = useRef(null);
  const [oldAnnotation, setoldAnnotation] = useState("");
  const newAnnotationText = useRef(null);
  const navigate = useNavigate();
  const [dropdownOptions, setDropdownOptions] = useState([]); 
  const [versionsdropdownOptions, setVersionsDropdownOptions] = useState([]); 

  useEffect(() => {

    async function fetch_issueType() {
      const issueTypelist = await loadIssueType(token);
      if (issueTypelist==="INVALID"){
        navigate("/");
      }else{

        console.log("Lista: " + issueTypelist[0].name_issue_type);
        const issueTypeNames = issueTypelist.map((element, index) => {
            return { id: element.id, name: element.name_issue_type, index: index };
          });

        issueTypeNames.unshift("Seleccione uno...");
        setDropdownOptions(issueTypeNames);
        
     }
    }

    fetch_issueType();

    console.log ("VALUEEE PERMISSSION //////////////////////// " + (permission));
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia


  useEffect(() => {
    console.log('serial_url:', serial_url);
    if (serial_url !== null) {
      serialInputRef.current.value = serial_url;
      handleFindBt();
    }
    
  }, [serial_url]); 

  const newAnnotation = () => {
    console.log ("Anotacion nueva> " + newAnnotationText.current.value  + " type:" + newType + " usrid" + usr_id);

      if(newType === ''){
        alert("Seleccione un tipo");
        return;
      }

      if(newAnnotationText.current.value === ''){
        alert("Debe escribir algun comentario");
        return;
      }

    async function fetchSeveDeviceAnnotation(theSerial) {
      
       
        const deviceMessages = await save_new_issue(token, theSerial, newAnnotationText.current.value, newType, usr_id, canal, persona_contacto.current.value, estado);
        if (deviceMessages==="INVALID"){
          navigate("/");
        }else{
            newAnnotationText.current.value = "";
            persona_contacto.current.value ="";

            setcanal(0);
            setestado(0);
            setNewType(0);
            handleFindBt();
            
       }
      }


      async function fetchupdateAnnotation(issue_id) {
      
       
        const deviceMessages = await update_issue(token, issue_id, newAnnotationText.current.value, newType, canal, persona_contacto.current.value, estado);
        if (deviceMessages==="INVALID"){
          navigate("/");
        }else{
            newAnnotationText.current.value = "";
            persona_contacto.current.value ="";

            setcanal(0);
            setestado(0);
            setNewType(0);
            handleFindBt();
       }
      }


      if (updating.current){
          console.log("issue: " + issue_updating_id);
        fetchupdateAnnotation(issue_updating_id);
      }else{
        fetchSeveDeviceAnnotation(serialInputRef.current.value);
      }

  


  }

  const handleFindBt = () => {

 
    async function fetchGetDevice(theSerial) {
      
    
      const deviceMessages = await get_devices_info(token, theSerial);
      if (deviceMessages==="INVALID"){
        navigate("/");
      } else if (deviceMessages==="NOT_FOUND"){
        if (permission === "1")
          alert("Serial " + theSerial + " no encontrado.");
        else
          alert("Serial " + theSerial + " no encontrado. O no tienes acceso a este serial.");
      }else{

        
        if (!(deviceMessages[0].model)) {
          alert("No existe el serial");
          return;
        }

        setModel (deviceMessages[0].model);
        setManufactured_date (deviceMessages[0].manufactured_date);
        setitem_status (deviceMessages[0].item_status);
        setwarranty_covered (deviceMessages[0].warranty_covered);
        setusr_name (deviceMessages[0].usr_name);
        setdasboard_link("https://lookerstudio.google.com/u/0/reporting/ebc08389-1c66-4462-819f-6419d8d181cc/page/ZAGwD?params=%7B%22ds0.serial%22:%22" +  theSerial  +
                                                                                                                                          "%22,%22ds2.serial%22:%22" + theSerial +
                                                                                                                                          "%22,%22ds4.serial%22:%22" + theSerial + 
                                                                                                                                          "%22,%22ds6.serial%22:%22" + theSerial + 
                                                                                                                                          "%22,%22ds8.serial%22:%22" + theSerial + 
                                                                                                                                          "%22,%22ds10.serial%22:%22" + theSerial + "%22%7D");

        const deviceIssues = deviceMessages["issues"].map((item, index) => ({
          comment: item.comment,
          date: item.date,
          usr_name: item.usr_name,
          id: item.id,
          name_issue_type:item.name_issue_type, 
          issue_type_id:item.issue_type,
          status: Number(item.status), canal:item.canal, contacto:item.contacto, 
          index: index

        }));

        setIssues(deviceIssues);


    
        
        const devicePackages = deviceMessages["packages"].map(item => ({
          name: item.package_name,
          date: item.last_update,
          current: item.current_version,
          destination: item.destination_version,
          id: item.id,
          name_issue_type:item.name_issue_type
        }));

        setPackages(devicePackages);

     }
    }

    fetchGetDevice(serialInputRef.current.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleFindBt();
    }
  };


  const handleDoubleClick = (index, destination) => {

      if (permission==="1"){
        setEditIndex(index);
        loadPackgesversions(packages[index].name, packages[index].current);

      }
  };

  const handleBlurOrEnter = (packageId, destination) => {
    handleUpdateDestination(packageId,destination);
  };
  
  const loadannotation = (annotationID) => {
    
    if (  parseInt(permission) < 3  ){
      updating.current=true;
      setoldAnnotation(deviceMSG[annotationID].comment);
      persona_contacto.current.value =deviceMSG[annotationID].contacto;
      setNewType(deviceMSG[annotationID].issue_type_id);
      setissue_updating_id(deviceMSG[annotationID].id);
      setcanal(deviceMSG[annotationID].canal);
      setestado(deviceMSG[annotationID].status);
   }


  };

  const cancelupdating = (annotationID) => {
    
    
    persona_contacto.current.value ="";

    setoldAnnotation("");
    setcanal(0);
    setestado(1);
    setNewType(0);
    updating.current=false;

  };


  /*
  Obtiene las versiones disponibles de cada paquete
  */

  const loadPackgesversions = (packageName, current_version) => {

     async function fetchPackageVersion(packageName, current_version) {
          const packageslist = await get_package_versions(token, packageName, current_version);
          if (packageslist==="INVALID"){
            navigate("/");
          }else if (packageslist.length ==0){
            const packageNamesversions = [];
            packageNamesversions.unshift({
              version: 0,
              version_detailed: "Updated"
            });
            setVersionsDropdownOptions(packageNamesversions);
          }else{
    
            console.log("Lista: " + packageslist.length);
        
            const packageNamesversions = packageslist.map((element, index) => {
              return { version: element.version, version_detailed: element.version_detailed, index: index };
            });
  
            packageNamesversions.unshift({
              version: 0,
              version_detailed: "Nueva"
            });
            setVersionsDropdownOptions(packageNamesversions);
            
         }
        }
    
        fetchPackageVersion(packageName, current_version);
  }



  const handleUpdateDestination = (packageId,newDestination) => {

    async function fetchUpdatemember() {
      console.log(packageId + " " + newDestination);
      const memberUpdate = await update_destination(token, packageId, newDestination);
      if (memberUpdate==="INVALID"){
        navigate("/");
      }else{
        setEditIndex(null);
        handleFindBt();
                
     }
    }
    fetchUpdatemember(packageId);
  };


  return (
    <div className="container">
      <div className="search-bar">
        <label htmlFor="serial">Serial Number: </label>
        <input type="text" id="serial" ref={serialInputRef} onKeyDown={handleKeyDown} />
        <button onClick={handleFindBt}>Find</button>
      </div>

      <label>Model: {model}  Manufactured: {manufactured_date} by {usr_name} </label>
      <br/>
      <label>Warranty until: {warranty_covered} Status: {item_status} </label>
      <br/>
      <label><a href={dasboard_link} target="_blank">Statistics</a>  </label>

     
      <div className="annotations"   >
        {deviceMSG.map((anno) => (
          <div key={anno.id} className="annotation" onClick={() => loadannotation(anno.index)}>
            <div className="header">
              <span>by: {anno.usr_name} - {anno.date} - {anno.contacto} - {anno.canal}</span>
              
              <span className="status">
                {anno.name_issue_type}
                <div className= {anno.status === 0 ? 'status-dot-green' : 'status-dot-red'}/>
      
              </span>
            </div>
            <div className="content" style={{ whiteSpace: "pre-line" }}>
              <p>{anno.comment}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="containerChk">
        
     { parseInt(permission) < 3  ?
      <div className="left-panel">
               {oldAnnotation !== "" ? (
                  <button style={{width: '100%'}} onClick={cancelupdating} > ❌ </button>
                ) : null}


        <div className="aditional-info">
           <label>Tipo: </label>
            <select  value={newType} onChange={(e) => setNewType(e.target.value)}>
                {dropdownOptions.map(({ id, name }, index) => (
                    <option key={id} value={id}  >
                    {id} {name}
                    </option>
                ))}
            </select>

            <label >Canal: </label>
            <select value={canal}   onChange={(e) => setcanal(e.target.value)}>
            
                    <option key={1} value="WhatsApp"  > WhatsApp </option>
                    <option key={2} value="Telefonico"  > Telefonico </option>
                    <option key={3} value="Email"  > Email </option>
                    <option key={4} value="Otro"  > Otro </option>
      
            </select>
            <br/>
            <br/>
            <label htmlFor="annotation-text">Persona de contacto: </label>
            <input type="text" id="contacto" ref={persona_contacto}  />

            <label htmlFor="annotation-text">Estado: </label>
            <select  value={estado} onChange={(e) => setestado(e.target.value)}>
            
                    <option key={0} value="0"  > Cerrado </option>
                    <option key={1} value="1"  > Abierto </option>
      
            </select>

        </div>
        <div className="new-annotation">
          
              {oldAnnotation !== "" ? (
              <textarea
                id="annotation-text"
                rows="4"
                cols="50"
                value={oldAnnotation} // Si deseas controlar el valor con el estado
                readOnly 
                disabled
              />
            ) : null}


          <textarea
              id="annotation-text"
              rows="4"
              cols="50"
              ref={newAnnotationText}
          />
        
          <button onClick={newAnnotation} > Guardar </button>
          </div>
      </div>
      :null}
      
  
      <div className="right-panel">
        <p> Versiones de paquetes principales</p>
        <div className='cards-list'>
        <table >
          <thead>
            <tr>
              <th>Paquete</th>
              <th>Ultima Act</th>
              <th>Actual</th>
              <th>Destino</th>
            </tr>
          </thead>
          <tbody>
            {packages.map((c_package, index) => (
                <tr
                  key={c_package.id}
                >
                  <td style={{ width: '40%' }}>{c_package.name}</td>
                  <td style={{ width: '30%' }}>{c_package.date}</td>
                  <td style={{ width: '10%' }}>{c_package.current}</td>
                  <td style={{ width: '20%' }}
                        onDoubleClick={() => handleDoubleClick(index, c_package.destination)}
                        onBlur={() => handleBlurOrEnter(c_package.id)}
                      >
                        {editIndex === index ? (
                          
                          <select value={0}   onChange={(e) => handleBlurOrEnter(c_package.id, e.target.value)}>
            
                                {versionsdropdownOptions.map((option, index) => (
                                <option key={index} value={option.version}  >
                                  {option.version_detailed}
                                </option>
                              ))}
                
                          </select>
                          
                        ) : (
                          c_package.destination
                        )}
                      </td>
                </tr>
              ))}
          </tbody>
        </table>
        </div>
      
      </div>
      
    </div>
    </div>


  );
};

export default Units_status;
