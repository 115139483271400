import React, { useEffect, useRef, useState } from 'react';
import './TalentStep1.css';
import { create_card_checkpoint, create_checkpoint, get_checkpoints, get_current_cards, get_current_sprint_cards, get_member_cards, peso, save_votes, set_dedication } from '../../../utils/talent_services';
import { redirect, useNavigate } from 'react-router-dom';


function TalentStep3 ({usr_id}) {

  const max_num_cards = 3;  // Numero maximo de tarjetas

  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  
  const [cards, setCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [countTarjetas, setcountTarjetas] = useState("");
  const [checkPoint_id, setcheckPoint_id] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

/**
 * Trae todas las tarjetas del ultimo checkpoint creado, asociadas a este miembro, solo si aun no tienen costo asociado
 */
function validate_last_checkpoint  ()  {
  async function fetchCheckpoints() {
      const checkpointlist = await get_checkpoints(token);
      if (checkpointlist==="INVALID"){
        navigate("/",  { state: { parametro: 'talent_step1' } });
      }else{
        //Elijo el checkpoint mas reciente que es el elemento 0 porque vienen ordenados por fecha
      
        let checkPoint_id = checkpointlist[0].id;
        console.log ("Checkpoint actual:" + checkPoint_id);
        setcheckPoint_id(checkPoint_id);
        
        const card_to_evaluate = await get_current_sprint_cards(token, checkPoint_id );
        if (card_to_evaluate!=="INVALID"){

            setCards([]);
     
                
            const currentKr = card_to_evaluate.map((celement, index) => {
                   
                    return {cpCardid: celement.id, selected:false,  cardname: celement.title, shortUrl:celement.shortURL, index: index };
                  });

                  setcountTarjetas(currentKr.length);
                  setCards(currentKr);
       }
       //Ahora obtengo las tarjetas de este check point
     }
    }
    fetchCheckpoints();
}


useEffect(() => {
  validate_last_checkpoint();
}, [token]); // Solo se vuelve a ejecutar si 'token' cambia



const filteredCards = cards.filter((card) =>
  card.cardname.toLowerCase().includes(searchTerm.toLowerCase())
);



const handleCheckboxChange = (cardindex, e)=>{

  const isChecked = e.target.checked;


  console.dir (selectedCards.length);

  if (isChecked){
    if(selectedCards.length < max_num_cards){
      filteredCards[cardindex].selected = true;
      setSelectedCards(prevCards => [...prevCards, filteredCards[cardindex]]);
    }
  }else{
    filteredCards[cardindex].selected = false;
    setSelectedCards((prevSelectedCards) => 
      prevSelectedCards.filter(element => element.index !== cardindex)
    );
  }

 
  
}

const save_votesNow = (cards) =>{
  if (cards.length>0){

    async function fetchsaveVotes() {
      const checkpointlist = await save_votes(token,checkPoint_id, selectedCards);
      if (checkpointlist==="INVALID"){
        navigate("/");
      }else{
        navigate("/talent_step4");
       }
   
    }
    fetchsaveVotes();
  }else{
    navigate("/talent_step4");
  }
}

const handlebt_create_checkpoint = () => {
  save_votesNow(selectedCards);
}


///////////


  return (
    <div className="containerChk">
     
      <div className="right-panel">
      <div className='button-container'>
             <button className="add-cards-btn" onClick={handlebt_create_checkpoint} >
            Siguiente
          </button>
          
        </div>
        <h4> Selecciona hasta 3 de las {countTarjetas} estrategias que te parezcan más importantes en este momento, aún si no participas de ella.</h4>
      
        <table className="selectedTable">
       
          <tbody>
            {selectedCards.map((card, index) => {
                    return (
                        <tr key={index}>
                        <td className="numbersRow" style={{ width: '10%' }}>
                         {index + 1}
                      </td>
                        <td style={{ width: '90%' }}><a href={card.shortUrl} target="_blank">{card.cardname}</a></td>
                        
                        </tr>
                      )
                }
              )}
          </tbody>
        </table>

        <input
            type="text"
            placeholder="Buscar por nombre..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
          />

        <div className='cards-list'>
        <table className="krtable">
          <thead>
            <tr>
              <th> </th>
              <th>Tarjetas</th>
            </tr>
          </thead>
          <tbody>
            {filteredCards.map((card, index) => {
                    return (
                        <tr
                          key={index}
                        >
                        <td style={{ width: '5%' }}>
                        <input
                          type="checkbox"
                          checked={card.selected}
                          onChange={(e) => handleCheckboxChange(index, e)} 
                        />
                      </td>
                        <td style={{ width: '95%' }}><a href={card.shortUrl} target="_blank">{card.cardname}</a></td>
                        
                        </tr>
                      )
                }
              )}
          </tbody>
        </table>
        </div>
        </div>
      
      
    </div>
  );
};

export default TalentStep3;
